'use strict';

angular.module('administrationPortal').controller('AdminCustomersCtrl', ['$scope', '$state', 'moreConstants', '$adminCustomerService', '$adminRegionService', '$adminBrandingService', '$adminAccessService', '$modalService', '$translate', '$sce', '$location', function ($scope, $state, moreConstants, $adminCustomerService, $adminRegionService, $adminBrandingService, $adminAccessService, $modalService, $translate, $sce, $location) {
  const self = this;

  $scope.regions = $adminRegionService.getRegions();
  $scope.trustLevels = $adminCustomerService.getTrustLevels();
  $scope.platformUrl = moreConstants.platformUrlProduction;
  $scope.formatDeleteOn = (deleteOn) => {
    if (Array.isArray(deleteOn)) {
      return new Date(`${deleteOn[0]}-${deleteOn[1]}-${deleteOn[2]}`).toLocaleDateString();
    } else if (deleteOn instanceof Date) {
      return deleteOn.toLocaleDateString();
    } else {
      return 'unknown';
    }
  };
  self.searchCustomers = searchCustomers;
  self.selectCustomer = selectCustomer;
  self.customer = undefined;

  self.query = '';

  init();

  function init() {
    const customerId = $location.search().customerId;
    if (customerId) {
      self.query = customerId;
      loadCustomer(customerId);
    }

    $scope.$watch(() => self.customer ? self.customer.customerId : undefined, (newVal) => {
      $location.search('customerId', newVal);
    });
  }

  function loadCustomer(customerId) {
    const customerPromise = $adminCustomerService.getCustomer(customerId);
    $scope.billing = $adminCustomerService.getCustomerBilling(customerId);
    $scope.subscriptionStatus = $adminCustomerService.getSubscriptionStatus(customerId);
    $scope.subscriptionList = $adminCustomerService.getSubscriptions(customerId);
    $scope.trust = $adminCustomerService.getCustomerTrust(customerId);
    $scope.folders = $adminCustomerService.getFolders(customerId);
    $scope.owner = $adminAccessService.getOwner(customerId);

    customerPromise.$promise.then((customer) => {
      self.customer = customer;
      loadBranding();
      loadSendingDomain();

      $scope.$watch('ctrl.customer.settings.segment', (newValue, oldValue) => {
        if (newValue && newValue !== oldValue) {
          $adminCustomerService.updateSegment(self.customer.customerId, self.customer.settings.segment);
        }
      });

      $scope.$watch('ctrl.customer.brandingId', (newValue, oldValue) => {
        if (newValue && newValue !== oldValue) {
          $adminCustomerService.updateBrandingId(self.customer.customerId, self.customer.brandingId).$promise.then(() => {
            loadBranding();
          });
        }
      });
    });
  }

  function searchCustomers(query) {
    self.customer = undefined;
    if (!query) {
      return;
    }
    const isNumber = !Number.isNaN(parseInt(query));
    const filter = {
      currentPage: 0,
      pageSize: 100,
      sort: [],
      query: [
        isNumber ? {path: 'customerId', value: query, type: 'string'} : {path: 'name', value: query, type: 'string'}
      ]
    };
    $adminCustomerService.getCustomersWithFilter(filter.currentPage, filter).$promise.then(customers => {
      self.customers = customers.elements;
      if (self.customers.length === 1) {
        selectCustomer({customerId: self.customers[0].customerId});
      }
    });
  }

  function selectCustomer($item) {
    loadCustomer($item.customerId);
  }

  $scope.transferCustomerToRegion = (region, previousRegionId) => {
    $modalService.confirmModal({
      title : 'Move customer to region',
      message : `Are you sure you want to move ${self.customer.name} to ${region.name}?`,
      confirmButtonTitle : 'Move',
      showExclamation : true,
      confirmButtonStyle : 'danger',
      onClose: (event, reason, closed) => {
        if (!closed) {
          self.customer.regionId = previousRegionId;
        }
      },
    }).then(() => {
      $adminRegionService.transferCustomerToRegion(self.customer.customerId, region.id).$promise.then(() => {
        // Nothing to do
      }, (err) => {
        $modalService.errorModal(err.data && err.data.message);
      });
    });
  };

  $scope.deleteCustomer = () => {
    const {customerId, name} = self.customer;

    $modalService.open({
      template: require('../../templates/portal/customers.delete.modal.html'),
      controller: ['$scope', function ($scope) {
        $scope.customer = {customerId, name};
        $scope.scheduleForDeletion = $scope.$close;
      }]
    }).then((deleteInPeriod) => {
      $adminCustomerService.scheduleCustomerForDeletion(self.customer.customerId, deleteInPeriod).$promise.then(() => {
        $state.go('portal.customers', {}, {reload: true});
      }, (err) => {
        $modalService.errorModal(err.data && err.data.message);
      });
    });
  };

  $scope.restoreForm = (form) => {
    $modalService.confirmModal({
      title : 'RESTORE_FORM',
      message : 'RESTORE_FORM_MESSAGE',
      confirmButtonTitle : 'RESTORE',
      showExclamation : true,
      confirmButtonStyle : 'primary'
    }).then(() => {
      $adminCustomerService.restoreForm(self.customer.customerId, form.id).$promise.then(() => {
        $scope.folders = $adminCustomerService.getFolders(self.customer.customerId);
      }, (err) => {
        $modalService.errorModal(err.data && err.data.message);
      });
    });
  };

  $scope.overrideTrustLevel = () => {
    $adminCustomerService.overrideTrustLevel(self.customer.customerId, $scope.trust.trustLevel).$promise.then(trust => $scope.trust = trust);
  };

  $scope.removeOverrideTrustLevel = () => {
    $adminCustomerService.removeOverrideTrustLevel(self.customer.customerId).$promise.then(trust => $scope.trust = trust);
  };

  $scope.addTrust = () => {
    // Customers start on score 50. They become trusted at 70+. Add 25 to make them trusted, unless there is funny business (trust decrease).
    $adminCustomerService.addTrust(self.customer.customerId, 25).$promise.then(trust => $scope.trust = trust);
  };

  $scope.renderSubscriptionStatusValue = (value, correctValue) => {
    const yesNo = value ? 'Yes' : 'No';
    return $sce.trustAsHtml(value === correctValue ? `<b style="color: green">${yesNo}</b>` : `<b style="color: red">${yesNo}</b>`);
  };

  function loadBranding() {
    $scope.brandings = $adminBrandingService.all();
    $scope.brandings.$promise.then(brandings => {
      $scope.branding = brandings.find(branding => branding.id === self.customer.brandingId);
    });
  }

  function loadSendingDomain() {
    $adminCustomerService.getSendingDomain(self.customer.customerId).$promise.then(sendingDomain => $scope.sendingDomain = sendingDomain);
  }

  $scope.updateBlock = (status) => {
    $adminCustomerService.putSubscriptionStatus(self.customer.customerId, {blockSubscription: status}).then(status => {
      $scope.subscriptionStatus = status;
    });
  };

  $scope.updateUnblock = (status) => {
    $adminCustomerService.putSubscriptionStatus(self.customer.customerId, {unblockSubscription: status}).then(status => {
      $scope.subscriptionStatus = status;
    });
  };

  $scope.confirmSubscription = () => {
    $adminCustomerService.confirmSubscription(self.customer.customerId).then(() => {
      $scope.subscriptionStatus = $adminCustomerService.getSubscriptionStatus(self.customer.customerId);
    });
  };

  $scope.refreshCustomer = () => {
    $adminCustomerService.refreshCustomer(self.customer.customerId).then(() => {
      self.customer = $adminCustomerService.getCustomer(self.customer.customerId);
    });
  };
}]);

'use strict';

angular.module('administrationPortal').controller('AdminUtilsMailSignatureCtrl', ['$timeout', function ($timeout) {
  const self = this;

  self.avatars = [
    'Adele.png',
    'Alicia.png',
    'Annemieke.png',
    'Arnau.png',
    'Arnau-Pi.png',
    'Beerd.png',
    'Clara.png',
    'Corne.png',
    'Dina.png',
    'Dirk-Jan.png',
    'Esmee.png',
    'Fabian.png',
    'Fiorina.png',
    'Gerard.png',
    'Glenn.png',
    'Greg.png',
    'Jana.png',
    'Johnny.png',
    'Joost.png',
    'Kayleigh.png',
    'Kimberley.png',
    'Laura.png',
    'Maarten.png',
    'Max.png',
    'Mindy.png',
    'Nienke.png',
    'Nina.png',
    'Raf.png',
    'Sharon.png',
    'Tara.png',
    'Thom.png',
    'Tomas.png',
    'Willem.png',
    'Yasmeen.png',
  ];
  self.languages = ['en', 'nl', 'es', 'de', 'fr', 'pt'];
  self.config = {
    language: 'en',
    name: '',
    role: '',
    avatar: '',
    linkedIn: 'https://www.linkedin.com/company/moreapp'
  };
  const translations = {
    en: {
      contactBtn: 'Get in touch',
      referBtn: 'Refer MoreApp',
      referUrl: 'https://moreapp.com/en/how-can-i-refer-moreapp/',
    },
    nl: {
      contactBtn: 'Neem contact op',
      referBtn: 'Beveel MoreApp aan',
      referUrl: 'https://moreapp.com/nl/aanbevelen-moreapp/',
    },
    de: {
      contactBtn: 'Kontakt aufnehmen',
      referBtn: 'MoreApp weiterempfehlen',
      referUrl: 'https://moreapp.com/de/moreapp-weiterempfehlen/',
    },
    es: {
      contactBtn: 'Contáctanos',
      referBtn: 'Recomendar MoreApp',
      referUrl: 'https://moreapp.com/es/recomendar-moreapp/',
    },
    fr: {
      contactBtn: 'Contactez-nous',
      referBtn: 'Recommander MoreApp',
      referUrl: 'https://moreapp.com/fr/recommander-moreapp/',
    },
    pt: {
      contactBtn: 'Contate-nos',
      referBtn: 'Recomendo MoreApp',
      referUrl: 'https://moreapp.com/pt-BR/recomendar-moreapp/',
    },
  };

  self.showExample = () => {
    const win = window.open('', 'Signature', 'width=500,height=200');
    win.document.body.innerHTML = getHtml(self.config);
  };

  self.copyToClipboard = () => {
    navigator.clipboard.writeText(getHtml(self.config));
    self.copied = true;
    $timeout(() => {
      self.copied = false;
    }, 3000);
  };

  init();

  ////////
  function init() {
  }


  function getHtml(config) {
    // DEV TIP:
    // Open admin.moreapp.com -> Utilities -> Mail signature, fill out form and hit "Copy HTML"
    // paste that HTML into a scratch to continue editing with actual data (images, names, etc)
    // when you're satisfied with the result, adjust the HTML below.
    return `<!DOCTYPE html>
    <html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width,initial-scale=1">
        <meta name="x-apple-disable-message-reformatting">
        <title></title>
        <!--[if mso]>
        <noscript>
            <xml>
                <o:OfficeDocumentSettings>
                    <o:PixelsPerInch>96</o:PixelsPerInch>
                </o:OfficeDocumentSettings>
            </xml>
        </noscript>
        <![endif]-->
        <style>
            table, td, div, p {
                font-family: "Arial", sans-serif;
            }
            a:link, a:visited, a:hover, a:focus, a:active {
                color: #2B4060;
                text-decoration: none;
            }
        </style>
    </head>
    <body style="color:#2B4060;">
    <table style="border: none; border-collapse: collapse;">
        <tr>
            <td style="width: 125px; height: 125px;">
                <a target="_blank" rel="noopener" href="${config.linkedIn}">
                    <img style="width: 100%;" src="https://cdn.moreapp.com/email/avatars/${config.avatar}" alt="Profile Picture of ${config.name}"/>
                </a>
            </td>
            <td style="padding-left: 24px;">
                <table style="align-items: start; border-collapse: collapse;">
                    <tr>
                        <td>
                            <a target="_blank" rel="noopener" href="https://moreapp.com/">
                                <img width="84" src="https://cdn.moreapp.com/email/moreapp.png" alt="MoreApp Logo"/>
                            </a>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding-top: 8px;">
                            <p style="font-size: 18px; color: #2B4060; margin: 0; font-family: 'Verdana', sans-serif">
                                ${config.name}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p style="font-size: 10px; color: #2B4060; margin:0;">
                                ${config.role}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding-top: 12px;">
                            <p style="font-size: 10px; color: #2B4060; margin:0;">
                                <a target="_blank" rel="noopener" href="https://moreapp.com/contact" style="color: #2B4060; text-decoration: underline;">${translations[config.language].contactBtn}</a> - <a target="_blank" rel="noopener" href="${translations[config.language].referUrl}" style="color: #2B4060; text-decoration: underline"/>${translations[config.language].referBtn}</a>
                            </p>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
    </body>
  </html>`;
  }

}]);
